import * as LDClient from 'launchdarkly-js-client-sdk';

const userMetatag = document.querySelector("meta[name='current-user']");
const requestMetadata = document.querySelector("meta[name='current-request']");
const clientId = document.querySelector(
  "meta[name='launchdarkly-client-id']",
).content;

if (userMetatag) {
  const employerId = userMetatag.getAttribute('data-employer-id');
  const email = userMetatag.getAttribute('data-email');
  const userId = userMetatag.content;

  if (employerId && email && userId) {
    const user = {
      key: userId,
      email,
      custom: {
        employer_id: Number(employerId),
        current_timestamp: new Date(),
      },
      ip: requestMetadata.dataset.ip,
    };
    const ldclient = LDClient.initialize(clientId, user);

    ldclient.on('ready', () => {
      // make the client globally available for use in React land
      window.ldclient = ldclient;
    });
  }
}
